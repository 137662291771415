
import { currency, slash } from "@/filters/utils";
import {
  EnterpriseTypes,
  EnterpriseWithVarieties,
} from "@/store/modules/enterprise/enterprise.types";
import { DefaultState, Pagination } from "@/types/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const enterprise = namespace("Enterprise");

@Component({
  components: {
    NoData: () => import("@/components/utils/NoData.vue"),
  },
  filters: {
    currency,
    slash,
  },
})
export default class EnterprisesView extends Vue {
  @Prop({ default: true }) pagination?: boolean;
  @Prop({ default: "" }) query?: string;

  @enterprise.State("enterprisesWithVarieties")
  public enterprises!: EnterpriseWithVarieties[];

  @enterprise.State("add")
  public addEnterprise!: DefaultState;

  @enterprise.State("pages")
  public pageCount!: number;

  @enterprise.State("loadingEnterpriseWithVarieties")
  public loadingEnterprises!: boolean;

  @enterprise.Mutation(EnterpriseTypes.SET_ADD_ENTERPRISE_DIALOG)
  public setAddEnterprise!: (addEnterprise: boolean) => void;

  @enterprise.Action(EnterpriseTypes.SEARCH_ENTERPRISES)
  public loadEnterprises!: (pagination: Pagination) => void;

  public page = 1;

  @Prop({ required: true, default: 10 }) public pageSize!: number;

  public headers = [
    {
      text: "NAME",
      sortable: true,
      value: "name",
    },
    {
      text: "VARIETIES",
      sortable: true,
      value: "varieties",
    },
    {
      text: "CREATED",
      sortable: true,
      value: "created_at",
    },
  ];

  closeAddEnterpriseDialog(): void {
    this.setAddEnterprise(false);
  }

  openAddEnterpriseDialog(): void {
    this.setAddEnterprise(true);
  }

  @Watch("pageSize")
  handlePageSizeChange(): void {
    this.page = 1;
    this.queryEnterprises();
  }

  @Watch("page")
  handlePageChange(): void {
    this.queryEnterprises();
  }

  queryEnterprises(): void {
    this.loadEnterprises({
      page: this.page,
      limit: this.pageSize,
      query: this.query,
    });
  }

  mounted(): void {
    if (this.enterprises.length == 0) this.queryEnterprises();
  }
}
